import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";

import Layout from "../components/layout";

const OtherPage = () => (
  <Layout title="other stuff">
    <div
      css={css`
        font-size: 2.5rem;
        /*color: #00f0ff;*/
        color: "White";
        position: relative;
        line-height: 2.5rem;
      `}
    >
      <section>
        <div css={{ marginBottom: "30px" }}>
          <Link to="/sketches">Sketches</Link>
        </div>
        <div css={{ marginBottom: "30px" }}>
          <Link to="/theories">Theories</Link>
        </div>
        <div css={{ marginBottom: "30px" }}>
          <Link to="/about">Contact</Link>
        </div>
        <div css={{ cmarginBottom: "30px" }}>
          <Link to="/all">Skeleton Key</Link>
        </div>
      </section>
    </div>
    <section
      css={css`
        position: absolute;
        bottom: 25px;
        left: 20px;
        opacity: 0.7;
      `}
    >
      <a href="https://www.paonix.com">Paonix</a>
    </section>
  </Layout>
);

export default OtherPage;
